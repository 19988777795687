<script lang="ts" setup> 

    const props = defineProps({ 
        blok: Object 
    })     

    useSeoMeta({
        title: props.blok?.seo_title,
        ogTitle: props.blok?.seo_title,
        twitterTitle: props.blok?.seo_title,
        description: props.blok?.seo_description,
        ogDescription: props.blok?.seo_description,
        twitterDescription: props.blok?.seo_title,
        twitterSite: '@id30'
    })

    if(props.blok?.seo_image && props.blok?.seo_image?.filename) {
        useSeoMeta({
            ogImage: props.blok?.seo_image?.filename,
            twitterImage: props.blok?.seo_image?.filename,
            twitterCard: 'summary_large_image'
        })
    }

</script>

<template>
    <main 
        v-if="blok" 
        v-editable="blok"
    >
        <StoryblokComponent v-for="section in blok.sections" :key="section._uid" :blok="section" />
    </main>
</template>